import React from 'react';

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white">
        <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
            <div className="pb-8">
                <p className="text-4xl font-bold inline border-b-4 border-gray-500">About</p>
            </div>

            <p className="text-xl mt-5">
              Seattle Resident, with a passion for making technology more accessible.
              Aspiring to become a full-stack engineer with an aim to improve user accessibility on web applications.
            </p>

            <br />

            <p className="text-xl">
              In my free time, I enjoy attending sporting events, spending time with family and friends, or playing soccer.
            </p>
        </div>
    </div>
  );
};

export default About;